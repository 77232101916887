import FeaturedCard from '@components/partials/common/card/featuredCard';
import OnlyfansCard from '@components/partials/common/card/OnlyfansCard';
import VideoCard from '@components/partials/common/card/videoCard';
import TagListNoExpand from '@components/partials/common/tags/tagListNoExpand';
import CarouselLeft from '@components/svgs/svgCarouselLeft';
import CarouselRight from '@components/svgs/svgCarouselRight';
import styles from '@styles/default-page-scss/carousel-cards/_carousel.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';

interface CarouselProps {
    items: any;
    type: string;
    numberOfSites?: boolean;
    style?: any;
}

interface ScrollableState {
    start: boolean;
    end: boolean;
    width: number;
}

const Carousel: FC<CarouselProps> = ({ items, type, numberOfSites = false, style = '' }): ReactElement => {
    const containerRef = useRef<HTMLInputElement>(null);

    const [scrollable, setScrollable] = useState<ScrollableState>({
        start: true,
        end: false,
        width: 0
    });

    const scrollDirection = (direction: number, type: string): void => {
        if (containerRef.current) {
            let scrollValueX = containerRef.current.clientWidth >= 720 ? 160 : 130; // log the X value added to scroll

            if (type == 'video') {
                scrollValueX = 420;
            }
            if (type == 'featured') {
                scrollValueX = 240;
            }
            containerRef.current.scrollBy({
                left: direction * scrollValueX,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container && container.scrollWidth === container.clientWidth) {
            setScrollable((prevState) => ({
                ...prevState,
                start: true,
                end: container.scrollWidth - container.clientWidth === 0 ? true : false
            }));
        }
    }, []);

    const handleScroll = (): void => {
        if (containerRef.current) {
            const container = containerRef.current;
            const scrollableWidth = container.scrollWidth - container.clientWidth;
            const currentScrollLeft = container.scrollLeft;
            const isAtBegin = currentScrollLeft === 0;
            const isAtEnd = scrollableWidth === 0 ? true : currentScrollLeft >= scrollableWidth - 5;

            setScrollable((prevState) => ({
                ...prevState,
                start: isAtBegin,
                end: isAtEnd
            }));
        }
    };

    const cardContent = (item: any, type: string, index: number): JSX.Element => {
        switch (type) {
            case 'card':
                return (
                    <Link href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${item.link}`}>
                        <a>
                            <li>
                                <div className={styles.card}>
                                    <p style={{ color: item.color }}>{item.name}</p>
                                    {item.image && (
                                        <Image
                                            unoptimized
                                            className={styles.image}
                                            src={item.image}
                                            alt={item.name}
                                            data-event-category="User Action"
                                            data-event-action={'thumb'}
                                            data-event-label={item.name}
                                            priority={true}
                                            layout="fill"
                                            objectFit="cover"
                                        />
                                    )}
                                </div>
                            </li>
                        </a>
                    </Link>
                );
            case 'tag':
                return <TagListNoExpand tag={item} numberOfSites={numberOfSites} />;
            case 'video':
                return <VideoCard site={item} />;
            case 'featured':
                return <FeaturedCard site={item} index={index} />;
            case 'onlyfansCarousel':
                return <OnlyfansCard site={item} />;
            default:
                return (
                    <figure className={styles.card}>
                        <p style={{ color: item.color ? item.color : '#fff' }}>{item.name}</p>
                        {item.image && (
                            <Image
                                unoptimized
                                className={styles.image}
                                src={item.image}
                                alt={`${item.name} Image`}
                                data-event-category="User Action"
                                data-event-action={'thumb'}
                                data-event-label={item.name}
                                layout="fill"
                                objectFit="cover"
                            />
                        )}
                    </figure>
                );
        }
    };
    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current;
            container.addEventListener('scroll', handleScroll);
            // Clean up the interval on component unmount
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    function getStyle(value: string) {
        const records: Record<string, string> = {
            CARD: `${styles.list} ${styles.default}`,
            TAG: `${styles.list} ${styles.small}`,
            FEATURED: `${styles.list} ${styles.default}`,
            ONLYFANSCAROUSEL: `${styles.list} ${styles.onlyfansList}`
        };
        return records[value] || styles.list;
    }

    return (
        <div className={`${styles.wrapper} ${style.type ? styles[style.type] : styles['default']}`}>
            <h2>{items.title}</h2>
            {style.type === 'center' || style.type === 'review' ? <div className={styles.line}></div> : ''}
            <div
                className={`${styles.container} ${style.container ? styles[style.container] : ''} ${
                    type == 'video' ? styles.translateX : ''
                }`}>
                <div className={styles.row}>
                    {/* Video Carousel Del margin */}
                    <section
                        className={`${styles.transform} ${style.container ? styles.left : styles.default}`}
                        ref={containerRef}>
                        {/* Render your cards here */}
                        <ul className={getStyle(type.toUpperCase())}>
                            {items.links.map((item: Record<string, string>, index: number) => (
                                <React.Fragment key={index}>{cardContent(item, type, index)}</React.Fragment>
                            ))}
                        </ul>
                    </section>
                </div>
                {/* Additional buttons for scrolling */}
                <div
                    className={`${styles.navigation} ${style.navigation ? styles[style.navigation] : ''} ${
                        scrollable.start === false && scrollable.end === false ? styles.default : ''
                    } ${scrollable.start ? styles.end : ''} ${scrollable.end ? styles.start : ''} `}>
                    <button onClick={() => scrollDirection(-1, type)} disabled={scrollable.start}>
                        <CarouselLeft />
                    </button>

                    <button onClick={() => scrollDirection(1, type)} disabled={scrollable.end}>
                        <CarouselRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Carousel;
